<template>
  <!--羽毛球排班规则-->
  <el-dialog
    class="badminton-rule-dialog"
    title="校区排期规则"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="1320px"
  >
    <div class="tip">
      规则会对整个校区的球场排期生效。
      <div>排期时，会根据间隔（分钟）自动生成时间段。</div>
    </div>
    <table-view :total="total"
                :query-info.sync="queryInfo"
                header-right
                @reload-table="renderTable">
      <template #header>
        <view-search-form
          ref="searchFormRef"
          :tool-list="['campus']"
          :queryInfo.sync="queryInfo"
          @on-search="renderTable(1)" />
      </template>
      <template #header-right>
        <el-button v-permission="['badminton:rule:edit']" type="primary" size="small" @click="newRow">新增规则
        </el-button>
      </template>
      <el-form ref="formRef" :model="formData" v-loading="loading" :rules="rules" style="padding-right: 0;">
        <el-table :data="formData.tableData">
          <el-table-column label="校区" prop="campusName" width="110px">
            <template v-slot="scope">
              <el-form-item
                v-if="scope.row.isEditing"
                :prop="`tableData.${scope.$index}.campusId`"
                :rules="rules.campusId">
                <el-select ref="roleREf" size="small" v-model="scope.row.campusId" placeholder="校区"
                           clearable
                           filterable>
                  <el-option v-for="{campusName,id} in campusList" :key="id"
                             :label="campusName"
                             :value="id" />
                </el-select>
              </el-form-item>
              <span v-else>{{ scope.row.campusName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="规则名" min-width="140px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.name`"
                            :rules="rules.name">
                <el-input size="small" v-model="scope.row.name" />
              </el-form-item>
              <span v-else>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="间隔(分钟)" min-width="160px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.orderInterval`"
                            :rules="rules.orderInterval">
                <el-input-number :min="30" :max="240" size="small" :precision="0" v-model="scope.row.orderInterval"
                                 controls-position="right" />
              </el-form-item>
              <span v-else>{{ scope.row.orderInterval }}</span>
            </template>
          </el-table-column>
          <el-table-column label="预约时长上限(分钟)" width="160px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.dayOrderMax`"
                            :rules="rules.dayOrderMax">
                <el-input-number :min="scope.row.orderInterval||30" :precision="0" :max="3600" size="small"
                                 controls-position="right"
                                 v-model="scope.row.dayOrderMax" />
              </el-form-item>
              <span v-else>{{ scope.row.dayOrderMax }}</span>
            </template>
          </el-table-column>
          <el-table-column width="140px">
            <template #header>
              <tool-tips-group
                tips-content="核销的上限为当前规则设定的间隔（分钟）。<br/>
                 例如：如果允许提前5分钟核销，且预约时间段为15:00-16:00,<br/>
                则可以在14:55-16:00之间都可以核销。">
                核销提前时间(分钟)
              </tool-tips-group>
            </template>
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.preFixedTime`"
                            :rules="rules.preFixedTime">
                <el-input-number :min="0" :max="scope.row.orderInterval" :precision="0" size="small"
                                 controls-position="right"
                                 v-model="scope.row.preFixedTime" />
              </el-form-item>
              <span v-else>{{ scope.row.preFixedTime }}</span>
            </template>
          </el-table-column>
          <el-table-column width="140px">
            <template #header>
              <tool-tips-group>
                <template #content>
                  规则说明‌：<br/>
                  设置自动取消时间（如10分钟）后，若用户预约时段为15:00-16:00：<br/>
                  <ul style="padding-left: 1em;">
                    <li style="list-style: disc;">15:10系统自动取消预约，用户加入黑名单，拉黑后无法再预约场地；</li>
                    <li style="list-style: disc;">该时段内场地将空置（不可预约）；</li>
                  </ul>
                </template>
                预约自动取消时间(分钟)
              </tool-tips-group>
            </template>
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.cancelFixedTime`"
                            :rules="rules.cancelFixedTime">
                <el-input-number :min="0" :max="scope.row.orderInterval" :precision="0" size="small"
                                 controls-position="right"
                                 v-model="scope.row.cancelFixedTime" />
              </el-form-item>
              <span v-else>{{ scope.row.cancelFixedTime }}</span>
            </template>
          </el-table-column>
          <el-table-column width="220" align="center">
            <template #header>
              <tool-tips-group tips-content="禁用规则后，该规则下的所有场地将不可用。">是否禁用</tool-tips-group>
            </template>
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.offFlag`">
                <el-switch
                  v-permission="['badminton:rule:edit']"
                  v-model="scope.row.offFlag"
                  active-color="var(--color-success)"
                  :active-value="0"
                  active-text="启用"
                  inactive-text="禁用"
                  :inactive-value="1"
                  @change="handleChange($event,scope.row)"
                />
              </el-form-item>
              <template v-else>
                <el-tag v-if="scope.row.offFlag" type="info">禁用</el-tag>
                <el-tag v-else type="success">启用</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="校区公告" width="160px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.announceText`"
                            :rules="rules.announceText">
                <el-input type="textarea" rows="3" size="small" v-model="scope.row.announceText" />
              </el-form-item>
              <span v-else>{{ scope.row.announceText }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="160px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.remarks`"
                            :rules="rules.remarks">
                <el-input type="textarea" rows="3" size="small" v-model="scope.row.remarks" />
              </el-form-item>
              <span v-else>{{ scope.row.remarks }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template v-slot="scope">
              <template v-if="scope.row.isEditing">
                <el-form-item>
                  <el-button
                    type="primary" size="small"
                    v-if="scope.row.isEditing" v-permission="['badminton:rule:edit']" @click="save(scope)">保存
                  </el-button>
                  <el-button size="small" @click="cancel(scope)">取消</el-button>
                </el-form-item>
              </template>
              <template v-else>
                <template v-if="!isEditing">
                  <el-button size="mini"
                             type="primary" plain
                             v-permission="['badminton:rule:edit']"
                             @click="editRow(scope)">编辑
                  </el-button>
                  <el-button size="mini"
                             type="danger"
                             v-permission="['badminton:rule:edit']"
                             @click="del(delBadmintonRuleByIdApi, scope.row.id,`排期规则-${scope.row.name}`)">
                    删除
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </table-view>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import Vue from 'vue'
import {
  delBadmintonRuleByIdApi,
  pageBadmintonRuleApi,
  saveBadmintonRuleApi
} from '@/api/student-manage/badminton-rule-api'

export default {
  name: 'badmintonRuleDialog',
  mixins: [dialog, tableView],
  props: {
    roleList: {
      type: Array,
      default: () => ([])
    },
    serviceList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      campusList: [],
      queryInfo: {
        campusId: ''
      },
      formData: {
        tableData: []
      },
      isEditor: false,
      isEditing: false,
      rules: {
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        },
        name: [{
          required: true,
          message: '请输入规则名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj],
        orderInterval: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        dayOrderMax: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        roomOrderMax: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        preFixedTime: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        cancelFixedTime: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        announceText: {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        },
        remarks: {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        }
      }
    }
  },
  mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('badminton:rule:edit')
    this.getCampusList()
    this.renderTable()
  },
  methods: {
    // 删除api
    delBadmintonRuleByIdApi,
    async renderTable() {
      this.loading = true
      try {
        const res = await pageBadmintonRuleApi(this.queryInfo)
        this.formData.tableData = res.data.list
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    editRow(scope) {
      this.isEditing = true
      scope.row.isEditing = true
      Vue.set(this.formData.tableData, scope.$index, scope.row)
    },
    newRow() {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          campusId: '',
          isEditing: true,
          campusName: '',
          name: '',
          announceText: '',
          orderInterval: 120,
          dayOrderMax: 10,
          roomOrderMax: 10,
          offFlag: 0,
          preFixedTime: 0,
          cancelFixedTime: 0,
          remarks: ''
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    async cancel(scope) {
      scope.row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, scope.$index, scope.row)
      await this.renderTable()
    },
    handleChange(val, row) {
      if (val) {
        this.$confirm('禁用规则后，该校区的所有羽毛球场地将不可预约，是否继续？', '提示').then().catch(() => {
          row.offFlag = 0
        })
      }
    },
    save(scope) {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm('点击确定校区排期规则。', '保存').then(async () => {
            try {
              await saveBadmintonRuleApi(scope.row)
              await this.renderTable()
              this.isEditing = false
            } catch (e) {
            }
          }).catch(() => {
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.set-fix-service-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .el-form {
      padding-right: 0;
    }
  }
}
</style>
